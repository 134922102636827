:root {
    --background-color: #01479B;
    --text-color: #FCFCFC;
    --cancel-btn-color: #EF0828;
}
* {
    box-sizing: border-box;
}
html,
body {
  height: 100vh;
  background: #FCFCFC;
}
#root {
  display: flex;
  height: 100vh;
}
.App {
    width: 100%;
    height: 100vh;
}
.main-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.banner-container {
    width: 100%;
    height: 10vh;
    z-index: 99;
}
.side-menu-container {
    width: 15.9%;
    height: 90vh;
    padding-top: 1.2rem;
    padding-right: 0.4rem;
}
.content {
    width: 100%;
    height: 90vh;
    display: flex;
}
.campaigns-container {
    width: 50%;
    height: 90vh;
    padding-top: 1.2rem;
    overflow-y: auto;
}
.historical-real-time-container {
    width: 52.1%;
    height: 90vh;
    padding-top: 1.2rem;
    overflow-y: auto; 
    /* overflow: hidden; */
}
/* .ant-tabs .ant-tabs-content {
    height: 40vh;
} */
/* .ant-tabs .ant-tabs-content div {
    height: 40%;
} */
.historical-container {
    width: 100%;
}
.real-time-container {
    width: 100%;
}
.wait-page-container {
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.automatic-load-container {
    width: 100%;
    height: 83vh;
    overflow: hidden;
}
.automatic-description-container {
    width: 100%;
    padding: 0rem 5rem;
    margin-top: 5rem;
}
.btn-progress-bar-container {
    width: 100%;
    padding: 0rem 5rem;
    margin-top: 10rem;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.ant-tabs >.ant-tabs-nav .ant-tabs-nav-list, :where(.css-dev-only-do-not-override-k7429z).ant-tabs >div>.ant-tabs-nav .ant-tabs-nav-list {
    opacity: 0;
}
.ant-menu-light.ant-menu-root.ant-menu-inline {
    border-inline-end: none;
}
.content>.start-end-inputs-container>.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
    width: 96%!important;
    border: 2px solid var(--background-color);
    text-align: center;
}
.content>.start-end-inputs-container>.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item, :where(.css-dev-only-do-not-override-k7429z).ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
    padding-inline-end: 0px!important;
}

.upload-container {
    position: relative;
}
.upload-container .ant-upload-list-item {
    position: absolute!important;
    top: -5px;
    left: 120px;
}

.ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters {
    cursor: default;
}

.table-personalized .ant-table-thead > tr > th  {
    background-color: #143C64;
    color: #fff;
}