.login-main-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--background-color);
    color: var(--text-color);
}
.login-main-container .login-container .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;
}
.login-main-container .login-container .logo-container img {
    width: 125px;
    transform: scale(1.6);
}
.login-main-container .login-container .ant-card .ant-card-body .ant-form .ant-form-item label {
    color: var(--text-color);
    font-weight: 700;
}
.login-main-container .ant-input-affix-wrapper >input.ant-input,
.login-main-container .ant-input-affix-wrapper .ant-input-suffix span {
    color: var(--text-color);
}